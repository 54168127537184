import React from "react";
import { Outlet } from "react-router-dom";
import Loader from "./Loader";
const Header = React.lazy(() => import("../pages/Header"));
const Footer = React.lazy(() => import("../pages/Footer"));

const Layout = () => {
  return (
    <div className="body-wrapper">
      <React.Suspense fallback={<Loader />}>
        <Header />
      </React.Suspense>
      <React.Suspense fallback={<Loader />}>
        <Outlet />
      </React.Suspense>
      <React.Suspense fallback={<Loader />}>
        <Footer />
      </React.Suspense>
    </div>
  );
};

export default Layout;
