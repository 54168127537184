export const ValuationAdvisorySubRoute = [
  {
    link: "/valuation-of-real-estate",
    lable: "Valuation of Real Estate",
  },
  {
    link: "/valuation-of-industrial-assets",
    lable: "Valuation Of Industrial Assets, Plant & Machinery",
  },
  {
    link: "/enterprise-valuation-and-business-valuation",
    lable: "Enterprise Valuation and Business Valuation",
  },
  {
    link: "/valuation-of-intangible-assets",
    lable: "Valuation of Intangible Assets",
  },
  {
    link: "/valuation-of-infrastructure-assets-specialized-assets",
    lable: "Valuation of Infrastructure Assets & Specialized Assets",
  },
  {
    link: "/valuation-of-intellectual-properties",
    lable: "Valuation of Intellectual properties",
  },
  {
    link: "/valuation-of-esops-sweat-equity",
    lable: "Valuation of ESOPs & Sweat Equity",
  },
  {
    link: "/valuation-for-merger-and-acquisition",
    lable: "Valuation for Merger & Acquisition",
  },
  {
    link: "/fairness-opinions",
    lable: "Fairness Opinions",
  },
  {
    link: "/valuation-for-insurance",
    lable: "Valuation for Insurance",
  },
];
