import React from "react";
import badgeLine from "../../../assets/images/badge-line.svg";
import pageBannerStart from "../../../assets/images/page-banner-start.svg";
import pageBanner from "../../../assets/images/page-banner.jpg";

import Slider from "./Slider";
import { Link } from "react-router-dom";

const ServiceData = ({
  boldHeader,
  unBoldHeader,
  title,
  bannerImg,
  boxNumber,
  boxTitle,
  description1,
  description2 = "",
  isSlider = false,
  sliderData = [],
}) => {
  return (
    <>
      <section className="page-banner pt-xs-60 pt-sm-80 overflow-hidden">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="page-banner__content mb-xs-10 mb-sm-15 mb-md-15 mb-20">
                <div className="transparent-text">Services</div>
                <div className="page-title">
                  <h1>
                    {boldHeader} <span>{unBoldHeader}</span>
                  </h1>
                </div>
              </div>

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {title}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6">
              <div className="page-banner__media mt-xs-30 mt-sm-40">
                <img src={pageBannerStart} className="img-fluid start" alt="" />
                <img src={pageBanner} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-110 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="why-choose__media-wrapper d-flex flex-column">
                <div className="gallery-bar"></div>

                <div className="why-choose__media">
                  <img src={bannerImg} alt="" className="img-fluid" />
                </div>

                <div className="global-country text-center">
                  <div className="number mb-5 color-white">
                    <span className="counter">{boxNumber}</span>
                  </div>
                  <h6 className="title color-white font-la">{boxTitle}</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="why-choose__content mt-lg-60 mt-md-50 mt-sm-40 mt-xs-35">
                <div className="why-choose__text mb-40 mb-md-35 mb-sm-30 mb-xs-30">
                  <span className="sub-title d-block fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-lg-20 mb-25">
                    <img src={badgeLine} className="img-fluid mr-10" alt="" />{" "}
                    Services
                  </span>
                  <h2 className="title color-pd_black">{title}</h2>

                  <div className="description font-la mt-20 mt-sm-15 mt-xs-10">
                    <p>{description1}</p>
                    <p>{description2}</p>
                  </div>
                </div>

                {/* <div className="why-choose__item-wrapper d-grid justify-content-between">
                  {valueOfRealEstate?.map((item, index) => (
                    <div key={index} className="why-choose__item">
                      <div className="icon mb-15 mb-md-10 mb-xs-5 color-primary">
                        <i className="icon-consultation"></i>
                      </div>
                      <h5 className="title color-d_black fw-500 mb-10">
                        {item?.title}
                      </h5>
                      <div className="description font-la">
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
            {isSlider && <Slider data={sliderData} />}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceData;
